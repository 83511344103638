import React, { useState } from "react";

import Balance from "../Balance/Balance";
import Form from "../Form/Form";

const TabbedComp = ({
  balance = true,
  contractBalances,
  myBalances,
  primaryLabel,
  secondaryLabel,
  tertiaryLabel,
  depositEthModal,
  setNewInteraction,
}) => {
  const [showContractBalance, setShowContractBalance] = useState(1);

  const showContractBalanceHandler = (id) => {
    setShowContractBalance(id);
  };

  const showAccountBalanceHandler = (id) => {
    setShowContractBalance(id);
  };

  return (
    <div className="flex-[0.65] rounded-2xl p-5 border bg-[#ECE9E9] ">
      <div className="flex gap-8 text-2xl font-semibold mb-5">
        <p
          onClick={() => showContractBalanceHandler(1)}
          className={`text-[${showContractBalance === 1 ? "#6F5CF1" : "#ACA4E5"}] cursor-pointer`}
        >
          {primaryLabel}
        </p>
        <p
          onClick={() => showAccountBalanceHandler(0)}
          className={`text-[${showContractBalance === 0 ? "#6F5CF1" : "#ACA4E5"}] cursor-pointer`}
        >
          {secondaryLabel}
        </p>
        <p
          onClick={() => showAccountBalanceHandler(2)}
          className={`text-[${showContractBalance === 2 ? "#6F5CF1" : "#ACA4E5"}] cursor-pointer`}
        >
          {tertiaryLabel}
        </p>
      </div>
      {balance ? (
        <>
          {showContractBalance === 1 ? (
            <div className="flex mt-4 gap-10 h-[10rem]">
              <Balance balance={contractBalances?.[1] || '-'} currency="USDC" type="contract" />
              <Balance balance={contractBalances?.[0] || '-'} currency="Ether" type="contract" />
            </div>
          ) : (
            <div className="flex mt-4 gap-10 h-[10rem]">
              <Balance balance={myBalances?.[1]} currency="USDC" type="account" />
              <Balance balance={myBalances?.[0]} currency="Ether" type="account" />
            </div>
          )}
        </>
      ) : (
        <>
          {showContractBalance === 1 ? (
            <div className="flex gap-10">
              <Form currency="ETH" deposit={true} setNewInteraction={setNewInteraction}  />
              <Form currency="USDC" deposit={true} setNewInteraction={setNewInteraction} />
            </div>
          ) :
          showContractBalance === 0 ? (
            <div className="flex gap-10">
              <Form deposit={false} request={true} setNewInteraction={setNewInteraction} />
              <Form deposit={false} request={false} setNewInteraction={setNewInteraction} />
            </div>
          ) : 
          <div className="flex gap-10">
            <Form deposit={false} request={true} emergency={true} setNewInteraction={setNewInteraction} />
            <Form deposit={false} request={false} emergency={true} setNewInteraction={setNewInteraction} />
          </div>
          }
        </>
      )}
    </div>
  );
};

export default TabbedComp;
