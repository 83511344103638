import { ethers } from "ethers";
import React from "react";

const Backdrop = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 h-screen w-screen bg-black/95"
    />
  );
};

const DetailsModal = ({ onClose, depositDetails, withdrawRequestDetails, withdraw = false, emergency = false}) => {
  return (
    <>
      <Backdrop onClose={onClose} />
      <section className="w-[30rem] mx-auto fixed left-[50%] top-[55%] -translate-x-[50%] -translate-y-[50%] rounded-md bg-white backdrop-blur-md py-8 px-5">
        <div className="px-5">
          <h2 className="text-[#9675c0] font-semibold text-2xl text-center mb-6">
            {`${withdraw ? emergency ? "Emergency" : "Withdraw" : "Deposit"} Request Details`}
          </h2>
          {withdraw ?
            <>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-xl flex-[0.4]">Coin : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{withdrawRequestDetails?.coin}</p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-lg flex-[0.4]">Amount : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{withdrawRequestDetails?.amount} <span className="text-sm text-gray-500">eth</span></p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-lg flex-[0.4]">Request Id : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{withdrawRequestDetails?.reqId}</p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="my-2">
                  <span className="text-xl font-semibold text-[#c30a45]">Remember it and keep it safe to withdraw your funds!!</span>.
                </p>
              </div>
            </>  
            :
            <>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-xl flex-[0.4]">Deposit Time : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{depositDetails?.[0]} <span className="text-sm text-gray-500">s</span></p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-lg flex-[0.4]">Amount : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{depositDetails?.[2]} <span className="text-sm text-gray-500">eth</span></p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-lg flex-[0.4]">Interest Rate : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{depositDetails?.[1]}</p>
              </div>
              <div className="flex gap-10 items-center">
                <p className="text-gray-600 font-semibold text-lg flex-[0.4]">Token Price : </p>
                <p className="text-xl text-black font-semibold flex-[0.6]">{depositDetails?.[3]}</p>
              </div>
            </>
          }
        </div>
      </section>
    </>
  );
};

export default DetailsModal;


