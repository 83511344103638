import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import RPC from "../../scripts/ethersRPC.ts";
import { TransactionContext } from "../../store/TransactionContext";
import DetailsModal from "../Modals/DetailsModal/DetailsModal";
import ListModal from "../Modals/ListModal/ListModal";
import TabbedComp from "../TabbedComp/TabbedComp";

const Dashboard = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [activeIdList, setActiveIdList] = useState([]);
  const [defaults, setDefaults] = useState(0);
  const [contractBalances, setContractBalances] = useState();
  const [myBalances, setMyBalances] = useState();
  const [depositId, setDepositId] = useState("");
  const [depositDetails, setDepositDetails] = useState();
  const [interaction, setInteraction] = useState(false);

  const { provider } = useContext(TransactionContext);

  const setNewInteraction = () => {
    setInteraction(!interaction);
  }


  const getDefaults = async () => {
    const rpc = new RPC(provider);
    const result = await rpc.viewFailed();
    setDefaults([Number(result[0]), Number(result[1])]);
  };

  const getContractBalances = async () => {
    const rpc = new RPC(provider);
    const etherBalance = await rpc.viewBalance();
    const tokenABalance = await rpc.viewtokenABalance();
    console.log(etherBalance, tokenABalance);
    setContractBalances([etherBalance, tokenABalance]);
  };

  const getMyBalances = async () => { 
    const rpc = new RPC(provider);
    const etherBalance = await rpc.viewMyBalance();
    const tokenABalance = await rpc.viewMytokenABalance();
    setMyBalances([etherBalance, tokenABalance]);
  }

  useEffect(()=>{
    function initValues() {
      if(!provider) {
        console.log("Dashboard, provider not initialised yet");
        return;
      }
      console.log("now initialised, getting data");
      getDefaults();
      getContractBalances();
      getMyBalances();
    }
    initValues();
  },[provider, interaction])

  const detailsModalHandler = async (event) => {
    event.preventDefault();
    if (depositId === "") {
      return alert("Please enter a deposit ID");
    }
    const rpc = new RPC(provider);
    try {
      const depositDetails = await rpc.viewMyDepositId(depositId);
      const TimeOfDeposit = ethers.BigNumber.from(depositDetails[0]).toNumber();
      const InterestRate = depositDetails[1];
      const EthAmount = ethers.utils.formatEther(depositDetails[2]);
      const TokenPrice = ethers.BigNumber.from(depositDetails[3]).toNumber();
      setDepositDetails([TimeOfDeposit, InterestRate, EthAmount, TokenPrice]);
      setShowDetailsModal(true);
      setDepositId("");
    }
    catch (error) {
      console.log(error);
    }
  };

  const getActiveDeposits = async () => {
    const rpc = new RPC(provider);
    try{
      const result = await rpc.viewMyActiveDeposits();
      const activeIds = result.map((id) => ethers.BigNumber.from(id).toNumber()).filter((id) => id !== 0);
      console.log(activeIds);
      setActiveIdList(activeIds);
      setShowListModal(true);
    }
    catch(error){
      console.log(error);
    }
  }

  const closeModal = () => {
    setShowDetailsModal(false);
    setShowListModal(false);
  };

  return (
    <section className="my-32">
      <p className="text-center text-4xl uppercase text-[#302A38] font-semibold">
        Dashboard
      </p>
      <div className="bg-[#F5F5F5] border flex flex-col gap-8 border-gray-200 p-8 w-[85%] mx-auto h-full rounded-lg my-10 shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)]">
        <div className="flex gap-8">
          {/* Defaults */}
          <div className="flex-[0.35] rounded-2xl bg-[#302A38] flex flex-col justify-center items-center py-20">
            <h2 className="text-gray-300 text-lg mb-2">No. of Defaults</h2>
            <p className="text-white font-semibold text-6xl">{`${defaults[0] || '0'}`}</p>
          </div>

          {/* Balance */}
          <TabbedComp
            balance={true}
            contractBalances={contractBalances}
            myBalances={myBalances}
            primaryLabel="Contract Balance"
            secondaryLabel="Account Balance"
          />
        </div>
        <div className="flex gap-8">
          <TabbedComp
            balance={false}
            primaryLabel="Deposit"
            secondaryLabel="Withdraw"
            tertiaryLabel="Emergency Exit"
            setNewInteraction={setNewInteraction}
          />
          <div className="flex-[0.35] rounded-2xl bg-[#302A38] flex flex-col justify-center items-center py-10">
            {/* <h2 className="text-white text-2xl mb-3 font-semibold">My Deposits</h2> */}

            <button onClick={getActiveDeposits} className="rounded-full bg-white mb-3 py-2 px-6 font-semibold text-xl text-[#6F5CF1] hover:bg-gray-200">
              List of Active Deposits
            </button>
            <p className="text-gray-300 font-bold">or</p>
            <p className="text-gray-300 text-sm mb-2">
              Enter your deposit id here 👇
            </p>
            <form className="flex flex-col">
              <input
                id="deposit"
                value={depositId}
                onChange={(e) => setDepositId(e.target.value)}
                className="bg-[#E7E7E7] py-2 px-2 rounded-md mb-5"
                type="number"
                placeholder="2"
              />
              <button
                type="submit"
                onClick={detailsModalHandler}
                className="rounded-full bg-white py-2 px-6 font-semibold text-xl text-[#6F5CF1] hover:bg-gray-200"
              >
                Get Details
              </button>
            </form>
          </div>

          {showDetailsModal ? <DetailsModal withdraw={false} depositDetails={depositDetails} onClose={closeModal} /> : null}
          {showListModal ? <ListModal activeIdList={activeIdList} onClose={closeModal} /> : null}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
