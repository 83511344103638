import React from "react";
import { IoClose } from "react-icons/io5";

const Backdrop = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 h-screen w-screen bg-black/50"
    />
  );
};

const WithdrawModal = ({ onClose, activeIdList }) => {
  return (
    <>
      <Backdrop onClose={onClose} />
      <section className="bg-white fixed w-[30rem] left-[50%] rounded-lg top-[40%] -translate-x-[50%] -translate-y-[50%] py-3 px-4">
        <div className="justify-between py-2">
          <h2 className="text-[#9675c0] font-semibold text-2xl text-center mb-6">
            Active Deposits IDs
          </h2>
        </div>
        <div className="flex flex-col gap-2 px-7">

        {
          activeIdList.map((id) => {
            return (
              <li key={id}>
                #{id}
              </li>
            )
          })
        }
        </div>
      </section>
    </>
  );
};

export default WithdrawModal;
