import React from "react";
import RPC from "../../scripts/ethersRPC.ts"
import { TransactionContext } from "../../store/TransactionContext";
import { useState } from "react";
import { useContext } from "react";
import { ethers } from "ethers";


const Backdrop = ({ onClose }) => {
    return (
        <div
            onClick={onClose}
            className="fixed top-0 left-0 h-screen w-screen bg-black/50"
        />
    );
};

const Register = ({ onClose, setShowRegisterModal, setShowReferralModal, setMyCode }) => {
    const { provider, login, setShowLoader } = useContext(TransactionContext);
    const [referralCodeEntered, setReferralCodeEntered] = useState("");
    const [showLoginError, setShowLoginError] = useState(false);

    const handleRegister = async () => {
        if(!provider) {
            setShowLoginError(true);
            setTimeout(() => {
                setShowLoginError(false);
            }, 11000);
            return;
        }
        const rpc = new RPC(provider);
        setShowLoader(true);
        try {
            await rpc.register(referralCodeEntered);
            const contract = await rpc.getContract();
            contract.once("Code", (MyCode) => {
                const formatedCode = ethers.BigNumber.from(MyCode).toNumber();
                setMyCode(formatedCode);
                setShowRegisterModal(false);
                setShowReferralModal(true);
                setShowLoader(false);
                console.log(MyCode);
            });
        }
        catch (error) {
            setShowLoader(false);
            console.log(error);
        }
    }


    return (
        <>
            <Backdrop onClose={onClose} />
            <section className="bg-white fixed w-[40rem] left-[50%] rounded-lg top-[40%] -translate-x-[50%] -translate-y-[50%] py-3 px-4">
                <div className="justify-between py-2">
                    <h2 className="text-[#3c29b5] font-bold text-2xl text-center mb-6">
                        Refer Friends and Earn!!
                    </h2>
                    <p className="text-center text-lg px-[4rem]">
                        Join our referral program by inviting your friends to register with your unique code.
                        You both get a 1% discount on your friends first investment.
                    </p>
                    <p className="text-center px-9">
                    </p>
                </div>
                <div className="flex flex-col mx-auto pb-3  mt-8 gap-2 w-[25rem]">
                    <label htmlFor="deposit" className="text-lg font-semibold mb-2">
                        Have a Referral Code?
                    </label>
                    <input
                        id="deposit"
                        value={referralCodeEntered}
                        onChange={(e) => setReferralCodeEntered(e.target.value)}
                        className="bg-[#E7E7E7] py-2 px-2 rounded-md mb-5"
                        type="number"
                        placeholder="Tip : You can register without it too!! 😉"
                    />
                    <button
                        type="submit"
                        onClick={handleRegister}
                        className="px-10 py-2 rounded-full text-white text-lg font-semibold bg-[#6F5CF1] hover:bg-[#4232ae]"
                    >
                        Register Now
                    </button>
                    {showLoginError && <p className="text-red-700 font-semibold ml-2 -mt-2">You must Login first Login first to register !!</p>}
                </div>
            </section>
        </>
    );
};

export default Register;
