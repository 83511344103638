import React, { useEffect, useState } from "react";
import { Web3Auth } from "@web3auth/modal";
import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import RPC from "../scripts/ethersRPC.ts";

export const TransactionContext = React.createContext();


export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [web3auth, setWeb3auth] = useState();
  const [provider, setProvider] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {

        const web3auth = new Web3Auth({
          clientId: "BDqMgj3HPn1H35i_851cTUC8l_WV9k8yyE-HA7e4En-EuzQ2rHPeGGqSdcQreoLB_cMtR6R5_tda-E4ZyOgfXOE",
          chainConfig: {
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: "0x5", // This is the chainId of the Goerli
            rpcTarget: "https://goerli-light.eth.linkpool.io/",
            // rpcTarget: "https://dawn-shy-county.ethereum-goerli.discover.quiknode.pro/87e3d3bf0615b0a7925b83f43c326d43ba110816/", // This is the public RPC we have added, please pass on your own endpoint while creating an app
          },
          uiConfig: {
            theme: "dark",
            loginMethodsOrder: ["facebook", "google"],
            appLogo: "https://web3auth.io/images/w3a-L-Favicon-1.svg", // Your App Logo Here
          },
          web3AuthNetwork: "testnet"
        });

        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            network: "cyan",
            uxMode: "popup",
            whiteLabel: {
              name: "Crypto Deposit",
              logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
              logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
              defaultLanguage: "en",
              dark: true, // whether to enable dark mode. defaultValue: false
            },
          },
        });
        web3auth.configureAdapter(openloginAdapter);
        setWeb3auth(web3auth);

        await web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "openlogin",
              loginMethods: {
                google: {
                  name: "google login",
                  logoDark: "url to your custom logo which will shown in dark mode",
                },
                facebook: {
                  // it will hide the facebook option from the Web3Auth modal.
                  showOnModal: true,
                },
              },
              // setting it to false will hide all social login methods from modal.
              showOnModal: true,
            },
          },
        });
        if (web3auth.provider) {
          setProvider(web3auth.provider);
        };
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);
  };

  const getUserInfo = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const user = await web3auth.getUserInfo();
    console.log(user);
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3auth.logout();
    setProvider(null);
  };

  const getChainId = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const chainId = await rpc.getChainId();
    console.log(chainId);
  };

  function truncate(str, maxDecimalDigits = 4) {
    if (str.includes('.')) {
      const parts = str.split('.');
      return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
    }
    return str;
  }

  // etherBalance
  const viewBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    try {
      const contract = await rpc.getContract();
      console.log(contract);
      // const result = await contract.viewBalance();
      const result = await rpc.viewBalance()
      console.log(result);
    }
    catch(error) {
      console.log(error);
    }
  }

  const getAccounts = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const address = await rpc.getAccounts();
    console.log(address);
    return address;
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const balance = await rpc.getBalance();
    console.log(balance);
  };

  return (
    <TransactionContext.Provider 
      value={{ 
        login, logout, viewBalance, getAccounts, getBalance, 
        currentAccount, provider, showLoader, setShowLoader
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
