import React, { useContext, useEffect, useState } from "react";
import { TransactionContext } from "../../store/TransactionContext";
import {IoMdCopy} from 'react-icons'
import RPC from './../../scripts/ethersRPC.ts';
import { IoCopyOutline } from "react-icons/io5";

const Navbar = () => {
  const { login, logout, provider, getAccounts, viewBalance } = useContext(TransactionContext);
  const [address, setAddress] = useState("");
  const [copySuccessMessage, setCopySuccessMessage] = useState('');
  const [showOnCopy, setShowOnCopy] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setCopySuccessMessage("Copied !!");
      setShowOnCopy(true);
      setTimeout(() => setShowOnCopy(false), 1000)
    }
    catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getAddress =  async () => {
      const add = await getAccounts();
      setAddress(add);
    }
    getAddress();
  }, [provider])
  

  return (
    <nav className="py-4 bg-inherit text-white">
      <div className=" w-[85%] mx-auto flex justify-between items-center">
        <h2 className="text-4xl font-semibold">CD.</h2>
        <ul className="flex space-x-16 text-md font-thin tracking-wider">
          <li>FAQ</li>
          <li>About Us</li>
          <li>Docs</li>
          <li>Contact</li>
        </ul>
        <div className="inline-block">
          {address && <button
            onClick={copyToClipboard}
            className="py-2 pt-3 px-4 rounded-md text-l bg-[#2F2E41] font-semibold hover:bg-[#3c29b5] w-[14rem]"
          >
            {showOnCopy ? 
              copySuccessMessage 
              : 
              <div className="flex items-center">
                <span className="overflow-scroll shrink transition duration-1000 hover:-translate-x-1">{address}</span>
                <IoCopyOutline className="ml-2 shrink-0" />
              </div>
            }
          </button>}
          <button 
            onClick={provider ? logout : login}
            className="py-2 px-6 rounded-md mx-1 text-xl bg-[#6F5CF1] font-semibold hover:bg-[#3c29b5]"
          >
            {provider ? "Logout !!" : "Connect Wallet"}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
