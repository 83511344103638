import { ethers } from "ethers";
import React, { useRef, useState } from "react";
import { contractAddress } from "../../constants/DepositContract/constants";
import RPC from "../../scripts/ethersRPC.ts";
import { TransactionContext } from "../../store/TransactionContext";
import DepositModal from "../Modals/DepositModal/DepositModal";
import DetailsModal from "../Modals/DetailsModal/DetailsModal";
import WithdrawModal from "../Modals/WithdrawModal/WithdrawModal";

const Form = ({ currency, deposit = true, request, setNewInteraction, emergency = false, }) => {
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showwithdrawlModal, setShowwithdrawlModal] = useState(false);
  const [generatedDepositID, setGeneratedDepositID] = useState("");
  const [withdrawRequestDetails, setWithdrawRequestDetails] = useState();
  const [withdrawlDetails, setWithdrawlDetails] = useState();
  const { provider, setShowLoader } = React.useContext(TransactionContext);
  const inputRef = useRef();

  const updateBalances = (prev) => {
    setNewInteraction(prev+1);
  }

  const withdrawHandler = async (event) => {
    event.preventDefault();
    let depositId = inputRef.current.value;
    if (depositId === "") {
      return alert("Please enter a deposit ID");
    }
    const rpc = new RPC(provider);
    setShowLoader(true);
    if (request === true) {
      try {
        if(emergency) 
          await rpc.emergencyWithdrawRequest(depositId); 
        else 
          await rpc.requestForWthdrawl(depositId);
        const contract = await rpc.getContract();
        contract.once("WithdrawRequest", (Coin, AmountToWithdraw, RequestId) => {
          const reqId = RequestId.toString();
          const coin = Coin.toString();
          const amount = ethers.utils.formatEther(AmountToWithdraw);
          setShowLoader(false);
          setNewInteraction();
          setWithdrawRequestDetails({ coin, amount, reqId });
          setShowDetailsModal(true);
        });
      }
      catch (err) {
        setShowLoader(false);
        return alert("Invalid deposit ID");
      }
    }
    else {
      try {
        await rpc.withdrawl(depositId);
        const contract = await rpc.getContract();
        contract.once("Withdraw", (USDCW, WEIW) => {
          const usdc = ethers.utils.formatEther(USDCW);
          const eth = ethers.utils.formatEther(WEIW);
          setShowLoader(false);
          setNewInteraction();
          setWithdrawlDetails({ usdc, eth });
          setShowwithdrawlModal(true);
        });
      }
      catch (err) {
        setShowLoader(false);
        return alert("Invalid request ID");
      }
    }
  };

  const depositHandler = async (event) => {
    event.preventDefault();
    let enteredAmount = inputRef.current.value;
    
    if (enteredAmount <= 0) {
      return alert("Enter amount greater than 0");
    }

    const rpc = new RPC(provider);
    setShowLoader(true);
    if (currency === "ETH") {
      try {
        await rpc.depositETH(enteredAmount);
        const contract = await rpc.getContract();
        contract.once("Deposits", (depositId, tokenDeposit, weiDeposit) => {
          setGeneratedDepositID(depositId);
          setShowLoader(false);
          setNewInteraction();
          setShowDepositModal(true);
        });
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    }
    else if (currency === "USDC") {
      try {
        await rpc.approve(contractAddress, enteredAmount);
        await rpc.depositTokenA(enteredAmount);
        const contract = await rpc.getContract();
        contract.once("Deposits", (depositId, tokenDeposit, weiDeposit) => {
          setGeneratedDepositID(depositId);
          setShowLoader(false);
          setNewInteraction();
          setShowDepositModal(true);
        });
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    }
  };

  const closeModal = () => {
    setShowDepositModal(false);
    setShowDetailsModal(false);
    setShowwithdrawlModal(false);
    setGeneratedDepositID("");
    inputRef.current.value = "";
  };

  return (
    <form className="flex-[0.5] flex flex-col bg-white items-center justify-around h-full rounded-2xl shadow-2xl py-10">
      {deposit ? (
        <>
          <label htmlFor="deposit" className="text-lg font-semibold mb-2">
            Deposit {currency} to contract
          </label>
          <input
            id="deposit"
            ref={inputRef}
            className="bg-[#E7E7E7] py-2 px-2 rounded-md mb-5"
            type="number"
            placeholder="0.002"
          />
          <button
            type="submit"
            onClick={depositHandler}
            className="px-10 py-2 rounded-full text-white text-lg font-semibold bg-[#6F5CF1] hover:bg-[#4232ae]"
          >
            Deposit {currency}
          </button>
        </>
      ) : (
        <>
          <label htmlFor="request" className="text-lg font-semibold mb-2">
            {`${request ? emergency ? "Request Emergency exit" : "Request for Withdrawal" : emergency ? "Emergency Withdrawal" :  "Withdraw"}`}
          </label>
          <input
            id="request"
            ref={inputRef}
            className="bg-[#E7E7E7] py-2 px-2 rounded-md mb-5"
            type="text"
            placeholder="Enter Id"
          />
          <button
            type="submit"
            onClick={withdrawHandler}
            className="px-10 py-2 rounded-full text-white text-lg font-semibold bg-[#6F5CF1] hover:bg-[#4232ae]"
          >
            {`${request ? "Request" : "Withdraw"}`}
          </button>
        </>
      )}
      {showDetailsModal && (
        <DetailsModal
          onClose={closeModal}
          withdrawRequestDetails={withdrawRequestDetails}
          withdraw={true}
          emergency={emergency}
        />
      )}
      {showDepositModal && (
        <DepositModal value={inputRef.current.value}
          depositID={generatedDepositID} onClose={closeModal}
        />
      )}
      {showwithdrawlModal && (
        <WithdrawModal withdrawlDetails={withdrawlDetails} onClose={closeModal} emergency/>
      )}
    </form>
  );
};

export default Form;
