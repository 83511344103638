import React from "react";

const Balance = ({ currency, balance, type }) => {
  return (
    <div className="flex-[0.5] flex flex-col bg-white items-center justify-around h-full rounded-2xl shadow-2xl py-10">
      <h3 className="text-lg flex flex-col items-center">Balance of {currency} <span className='text-sm text-gray-300'>in {type}</span></h3>
      <p className="text-3xl font-semibold mt-5">
        {balance} {currency}
      </p>
    </div>
  );
};

export default Balance;
