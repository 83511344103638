import type { SafeEventEmitterProvider } from "@web3auth/base";
import { ethers } from "ethers";
import { contractAddress, contractABI } from "../constants/DepositContract/constants";
import { USDC_CONTRACT_ABI, USDC_CONTRACT_ADDRESS } from "../constants/USDZcontract/constants";



export default class EthereumRpc {
  private provider: SafeEventEmitterProvider;


  constructor(provider: SafeEventEmitterProvider) {
    this.provider = provider;
  }

  // **************************Wallet Functions************************
  async getChainId(): Promise<any> {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider);
      // Get the connected Chain's ID
      const networkDetails = await ethersProvider.getNetwork();
      return networkDetails.chainId;
    } catch (error) {
      return error;
    }
  }

  async getAccounts(): Promise<any> {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider);
      const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      const address = await signer.getAddress();

      return address;
    } catch (error) {
      return error;
    }
  }

  async getBalance(): Promise<string> {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider);
      const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      const address = await signer.getAddress();

      // Get user's balance in ether
      const balance = ethers.utils.formatEther(
        await ethersProvider.getBalance(address) // Balance is in wei
      );

      return balance;
    } catch (error) {
      return error as string;
    }
  }

  async sendTransaction(): Promise<any> {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider);
      const signer = ethersProvider.getSigner();

      const destination = "0x40e1c367Eca34250cAF1bc8330E9EddfD403fC56";

      // Convert 1 ether to wei
      const amount = ethers.utils.parseEther("0.001");

      // Submit transaction to the blockchain
      const tx = await signer.sendTransaction({
        to: destination,
        value: amount,
        maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
        maxFeePerGas: "6000000000000", // Max fee per gas
      });

      // Wait for transaction to be mined
      const receipt = await tx.wait();

      return receipt;
    } catch (error) {
      return error as string;
    }
  }

  async signMessage() {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider);
      const signer = ethersProvider.getSigner();

      const originalMessage = "YOUR_MESSAGE";

      // Sign the message
      const signedMessage = await signer.signMessage(originalMessage);

      return signedMessage;
    } catch (error) {
      return error as string;
    }
  }

  async getPrivateKey(): Promise<any> {
    try {
      const privateKey = await this.provider.request({
        method: "eth_private_key",
      });

      return privateKey;
    } catch (error) {
      return error as string;
    }
  }

  //***************************Crypto Deposit Contract*******************

  // get Crypto Deposit Contract Instance
  async getContract(): Promise<any> {
    try{
      const signer = new ethers.providers.Web3Provider(this.provider).getSigner();
      const transactionContract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );
      return transactionContract;
    }
    catch (error) {
      console.log(error);
    }
  }

  async register(referralCode): Promise<any> {
    try {
      if(referralCode === "")
        referralCode = "0";
      const contract = await this.getContract();
      await contract.register(referralCode);
    }
    catch(error) {
      console.log(error);
    }
  }

  async viewMyDownlineCount(): Promise<any> {
    try {
      const contract = await this.getContract();
      const count = await contract.viewMyDownlineCount();
      return count;
    }
    catch(error) {
      console.log(error);
    }
  }

  async viewMyCode(): Promise<any> {
    try {
      const contract = await this.getContract();
      const code = await contract.viewMyCode();
      return code;
    }
    catch(error) {
      console.log(error);
    }
  }

  truncate = (str:string, maxDecimalDigits = 4) => {
    if (str.includes('.')) {
        const parts = str.split('.');
        return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
    }
    return str;
  }
  
  // etherBalance
  async viewBalance():Promise<String|undefined> {
    try {
      const contract = await this.getContract();
      const result = await contract.viewBalance();
      const formated = ethers.utils.formatEther(result);
      return this.truncate(formated);
    }
    catch (error) {
      console.error(error);
    }
  }
  
  // viewtokenABalance
  async viewtokenABalance(): Promise<any>  {
    try {
      const contract = await this.getContract();
      const result = await contract.viewtokenABalance();
      const formated = ethers.utils.formatEther(result);
      return this.truncate(formated);
    }
    catch (error) {
      console.error(error);
    }
  }
  

  // etherBalance
  async viewMyBalance(): Promise<any>  {
    try {
      const contract = await this.getContract();
      const result = await contract.viewMyBalance();
      const formated = ethers.utils.formatEther(result);
      return this.truncate(formated);
    }
    catch(error) {
      console.error(error);
    }
  }
  
  async viewMytokenABalance(): Promise<any>  {
    try {
      const contract = await this.getContract();
      const result = await contract.viewMytokenABalance();
      const formated = ethers.utils.formatEther(result);
      return this.truncate(formated);
    }
    catch(error) {
      console.error(error);
    }
  }
  
  // viewFailed attempts to withdraw
  async viewFailed(): Promise<any>  {
    try {
      const contract = await this.getContract();
      const result = await contract.viewFailed();
      return result;
    }
    catch (error) {
      console.error(error);
    }
  }
  
  // depositETH
  async depositETH(amount): Promise<any> {
    const contract = await this.getContract();
    const result = await contract.depositETH({
      value: ethers.utils.parseEther(amount),
    });
    return result;
  }
  
  // depositTokenA
  async depositTokenA(amount): Promise<any> {
    const contract = await this.getContract();
    const units = ethers.utils.parseUnits(amount).toString();
    const result = await contract.depositTokenA(units);
    return result;
  }
  
  // requestWithdrawal
  async requestForWthdrawl(depositId): Promise<any> {
    const contract = await this.getContract();
    const result = await contract.requestForWthdrawl(depositId);
    return result;
  }

  //emergencyRequestForWithdrawal
  async emergencyWithdrawRequest(depositId): Promise<any>{ 
    const contract = await this.getContract();
    const result = await contract.emergencyWithdrawRequest(depositId);
    return result;
  }
  
  //withdrawETH
  async withdrawl(requestId): Promise<any> {
    const contract = await this.getContract();
    const result = await contract.withdrawl(requestId);
    return result;
  }
  
  // viewMyActiveDeposits
  async viewMyActiveDeposits(): Promise<any>  {
    const contract = await this.getContract();
    const result = await contract.viewMyActiveDeposits();
    return result;
  }
  
  //get deposit details
  async viewMyDepositId(depositId): Promise<any> {
    try {
      const contract = await this.getContract();
      const result = await contract.viewMyDepositId(depositId);
      return result;
    }
    catch(error) {
      console.log(error);
    }
  }


  // **********************USDC Contract*********************

  // Get USDC contract Instance
  async approve(spender, amount) {
    try {
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const signer = web3Provider.getSigner();
      const contract = new ethers.Contract(
        USDC_CONTRACT_ADDRESS, 
        USDC_CONTRACT_ABI,
        signer
      );
      await contract.approve(spender, ethers.utils.parseEther(amount));
    }
    catch(error) {
      console.log(error);
    }
  }
}