import React from "react";
import { IoClose } from "react-icons/io5";

const Backdrop = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 h-screen w-screen bg-black/50"
    />
  );
};

const DepositModal = ({ onClose, value, depositID }) => {
  return (
    <>
      <Backdrop onClose={onClose} />
      <section className="bg-white fixed w-[60rem] left-[50%] rounded-lg top-[20%] -translate-x-[50%] -translate-y-[50%] py-3 px-4">
        <div className="flex justify-between py-2">
          <h2 className="text-3xl text-[#3c29b5] font-semibold mb-2">
            Congratulationssss!! 🎊🥳
          </h2>
          <IoClose onClick={onClose} className="cursor-pointer text-2xl" />
        </div>

        <p>
          You have successfully deposited{" "}
          <span className="text-xl font-semibold text-[#3c29b5]">{value}eth</span>.
        </p>
        <p>
          Here's your Deposit Id :{" "}
          <span className="text-xl font-semibold text-[#c30a45]">{`#${depositID}`}</span>.
          Remember it, or check the list of Active Deposits if you forgot!
        </p>
      </section>
    </>
  );
};

export default DepositModal;
