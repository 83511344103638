import React, { useContext, useEffect, useState } from "react";
import RPC from '../../scripts/ethersRPC.ts'
import { ImWhatsapp } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";
import { TransactionContext } from "../../store/TransactionContext";
import { ethers } from "ethers";


const Backdrop = ({ onClose }) => {
    return (
        <div
            onClick={onClose}
            className="fixed top-0 left-0 h-screen w-screen bg-black/50"
        />
    );
};

const ReferralCode = ({ onClose, setShowRegisterModal, setShowReferralModal, myCode }) => {
    const { provider } = useContext(TransactionContext);
    const [copySuccessMessage, setCopySuccessMessage] = useState("");
    const [showOnCopy, setShowOnCopy] = useState(false);
    const [downLineCount, setDownLineCount] = useState(0);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText("123456");
            setCopySuccessMessage("Copied !!");
            setShowOnCopy(true);
            setTimeout(() => setShowOnCopy(false), 1000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const getDownLineCount = async () => {
            const rpc = new RPC(provider);
            try {
                const count = await rpc.viewMyDownlineCount();
                const formatedCount = ethers.BigNumber.from(count).toNumber();
                setDownLineCount(formatedCount);
            }
            catch(error) {
                console.log(error);
            }
        }
        getDownLineCount();
    }, [])

    const handleWhastapp = () => {
        /*
        Text: Hey check out this awesome project https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `https://api.whatsapp.com/send?text=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app/%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    const handleTelegram = () => {
        /*
        Text: Hey check out this awesome project https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `https://telegram.me/share/url?url=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    const handleTwitter = () => {
        /*
        Text: Hey check out this awesome website https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `https://twitter.com/intent/tweet?text=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    const handleFacebook = () => {
        /*
        Text: Hey check out this awesome project https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=https://www.cryptodeposit.finance/&quote=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    const handleLinkedin = () => {
        /*
        Text: Hey check out this awesome project https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=https://crypto-deposit-mvp.vercel.app/&title=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    const handleEmail = () => {
        /*
        Text: Hey check out this awesome project https://www.cryptodeposit.finance/ and use my referral code 123456 to get 1% of your first deposit
        on your first deposit
        */
        window.open(
            `mailto:?subject=Hey%20check%20out%20this%20awesome%20website%20https%3A%2F%2Fcrypto-deposit-mvp.vercel.app%2F%20and%20use%20my%20referral%20code%20${myCode}%20to%20get%201%25%20of%20your%20first%20investment%20as%20a%20free%20reward.`,
            "_blank"
        );
    };

    return (
        <>
            <Backdrop onClose={onClose} />
            <section className="bg-white fixed w-[40rem] left-[50%] rounded-lg top-[40%] -translate-x-[50%] -translate-y-[50%] py-3 px-4">
                <div className="justify-between py-2">
                    <h2 className="text-[#3c29b5] font-bold text-2xl text-center mb-6">
                        You are now Registered !!🎊
                    </h2>
                    <p className="text-center text-lg px-[4rem]">
                        Use the buttons below to share your referral code with your friends
                        and earn 1% of their first deposits.
                    </p>
                    <p className="text-center px-9"></p>
                </div>
                <div className="flex flex-col mx-auto mt-4 gap-2 w-[25rem]">
                    <p className="text-2xl mx-auto font-semibold mb-4">
                        You have {downLineCount} referrals.
                    </p>
                    <div className="flex gap-2 mx-auto mb-2">
                        <button onClick={handleWhastapp} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#25D366]">
                            <ImWhatsapp className="text-white" />
                        </button>
                        <button onClick={handleTwitter} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#1DA1F2]">
                            <BsTwitter className="text-white" />
                        </button>
                        <button onClick={handleFacebook} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#4267B2]">
                            <AiFillFacebook className="text-white" />
                        </button>
                        <button onClick={handleTelegram} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#0088CC]">
                            <FaTelegramPlane className="text-white" />
                        </button>
                        <button onClick={handleEmail} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#D44638]">
                            <RiMailSendLine className="text-white" />
                        </button>
                        <button onClick={handleLinkedin} className="flex items-center justify-center w-10 h-10 rounded-full bg-[#0A66C2]">
                            <AiFillLinkedin className="text-white" />
                        </button>
                    </div>
                    <div className="mx-auto mb-2 p-3 border rounded-xl border-[#ABABAB]">
                        <span className="font-light text-2xl tracking-widest">{myCode}</span>
                    </div>
                    <button
                        type="submit"
                        onClick={copyToClipboard}
                        className="px-10 mx-auto w-1/2 py-2 mb-3 rounded-full text-white text-lg font-semibold bg-[#6F5CF1] hover:bg-[#4232ae]"
                    >
                        {showOnCopy ? copySuccessMessage : "Copy Code"}
                    </button>
                </div>
            </section>
        </>
    );
};

export default ReferralCode;
