import React, { useContext } from "react";
import Navbar from "../Navbar/Navbar";
import { TransactionContext } from "../../store/TransactionContext";
import RPC from "../../scripts/ethersRPC.ts";
import Register from './../Register/Register'
import ReferralCode from "../Register/ReferralCode";
import headerImg from "../../assets/header.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { ethers } from "ethers";

const Header = () => {

  const [showRegisterModal, setShowRegisterModal] = React.useState(false);
  const [showReferralModal, setShowReferralModal] = React.useState(false);
  const [myCode, setMyCode] = React.useState("");
  const { provider, setShowLoader } = useContext(TransactionContext);


  const handleRegister = async () => {
    if(!provider) {
      console.log("there is no provider");
      setShowRegisterModal(true);
      setShowReferralModal(false);
      return;
    }
    setShowLoader(true);
    try{
      const rpc = new RPC(provider);
      const code = await rpc.viewMyCode();
      const referralCode = ethers.BigNumber.from(code).toNumber();
      setShowLoader(false);
      if(referralCode !== 0) {
        setMyCode(referralCode);
        setShowRegisterModal(false);
        setShowReferralModal(true);
      }
      else {
        setShowRegisterModal(true);
        setShowReferralModal(false);
      }
    }

    catch(error) {
      setShowLoader(false);
      console.log(error);
    }
  }

  return (
    <header className="bg-[#150C21] h-screen">
      <Navbar />
      <div className="flex w-[85%] mx-auto mt-20 justify-around ">
        <div className="py-24">
          <h2 className="text-white text-7xl font-semibold mb-6">
            CryptoDeposit.
          </h2>
          <p className="text-[#6F657B] text-lg">
            A one stop solution for all your crypto currencies.
          </p>
          <button onClick={handleRegister} className="text-white flex items-center py-3 px-8 rounded-full text-xl mt-10 bg-[#6F5CF1] font-semibold hover:bg-[#3c29b5]">
            <p className="mr-2">Register</p>{" "}
            <span>
              <AiOutlineArrowRight />
            </span>
          </button>
        </div>
        <div>
          <img className="w-[25rem]" src={headerImg} alt="Header" />
        </div>
      </div>
      {showRegisterModal && <Register onClose={() => setShowRegisterModal(false)} setShowRegisterModal={setShowRegisterModal} setShowReferralModal={setShowReferralModal} setMyCode={setMyCode} />}
      {showReferralModal && <ReferralCode onClose={() => setShowReferralModal(false)} setShowRegisterModal={setShowRegisterModal} setShowReferralModal={setShowReferralModal} myCode={myCode} />}
    </header>
  );
};

export default Header;
